import React, { useState } from 'react';
import {
  GoogleLogin,
  GoogleLogout,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import styled from 'styled-components';

const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  '1041851114278-3hrv4t0gfa3r76mgl5na1difu2nu0fl4.apps.googleusercontent.com';

interface User {
  name: string;
  imageURL: string;
  email: string;
}

const AuthScreen: React.FC<{
  user: User;
  onLogout: () => void;
}> = ({ user, onLogout }) => {
  return (
    <>
      <img alt="User Google profile" src={user.imageURL} />
      <h2>
        Welcome {user.name} <code>({user.email})</code>
      </h2>
      <GoogleLogout clientId={GOOGLE_CLIENT_ID} onLogoutSuccess={onLogout} />
    </>
  );
};

const LoginScreen: React.FC<{
  onLogin: (user: User) => void;
  onError: (error: Error) => void;
}> = ({ onLogin, onError }) => {
  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      cookiePolicy={'single_host_origin'}
      onSuccess={(
        response: GoogleLoginResponse | GoogleLoginResponseOffline
      ) => {
        if ('code' in response) {
          console.error(response);
          onError(new Error(response.code));
        } else {
          const profile = response.getBasicProfile();
          onLogin({
            name: profile.getName(),
            imageURL: profile.getImageUrl(),
            email: profile.getEmail(),
          });
        }
      }}
      onFailure={error => {
        console.error(error);
        onError(
          error instanceof Error
            ? error
            : new Error(error.details || error.error)
        );
      }}
    />
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<Error | null>(null);

  return (
    <Wrapper>
      {error && <pre>{error.message}</pre>}
      {user ? (
        <AuthScreen
          user={user}
          onLogout={() => {
            setUser(null);
          }}
        />
      ) : (
        <LoginScreen
          onLogin={u => {
            setUser(u);
          }}
          onError={e => {
            setError(e);
          }}
        />
      )}
    </Wrapper>
  );
};

export default App;
